import { formatCurrency } from 'src/helpers/units';

//TODO: need to update the props everywhere this is used
export default function DedicatedIpCost({
  priceOfEachDedicatedIp,
  quantity,
  billingPeriodOfDedicatedIp
}) {
  return `${formatCurrency(priceOfEachDedicatedIp * quantity)} per ${billingPeriodOfDedicatedIp}`;
}
