export const ROLES = {
  ADMIN: 'admin',
  DEVELOPER: 'developer',
  TEMPLATES: 'templates',
  REPORTING: 'reporting',
  SUBACCOUNT_REPORTING: 'subaccount_reporting',
  SUBACCOUNT_DEVELOPER: 'subaccount_developer',
  SUPERUSER: 'superuser',
  CUSTOM: 'custom'
};

export const ROLE_LABELS = {
  [ROLES.TEMPLATES]: 'Templates',
  [ROLES.ADMIN]: 'Admin',
  [ROLES.DEVELOPER]: 'Developer',
  [ROLES.REPORTING]: 'Reporting',
  [ROLES.SUBACCOUNT_REPORTING]: 'Reporting',
  [ROLES.SUBACCOUNT_DEVELOPER]: 'Developer',
  [ROLES.SUPERUSER]: 'Super User',
  [ROLES.CUSTOM]: 'Custom'
};

export const ROLES_CONTENT = [
  {
    label: 'Admin',
    value: ROLES.ADMIN,
    helpText:
      'This role is a full access user. Admins have all permissions available and are the only users that can manage users, security, and billing settings.'
  },
  {
    label: 'Developer',
    value: ROLES.DEVELOPER,
    helpText:
      'This role is a powerful setup and development user. Developer users are able to create and edit API Keys and have access to all email related account settings.'
  },
  {
    label: 'Templates',
    value: ROLES.TEMPLATES,
    helpText:
      'This role is a content and campaign management user. These users can manage Templates, AB Tests, Recipient Lists, Suppressions. They also have view only access to Domains, Subaccounts, IP Pools, and all reporting and analytics features.'
  },
  {
    label: 'Reporting',
    value: ROLES.REPORTING,
    helpText:
      "This role is a data analytics user. They have access to all reporting features and View Only access to Domains, Subaccounts, and IP Pools. Reporting users can't edit any account or feature settings"
  },
  {
    label: 'Custom',
    value: ROLES.CUSTOM,
    helpText:
      'This role is a configurable user with fine-grained permissions. These users have access only to the features explicitly granted to them.'
  }
];

export const POLICIES = {
  'alerts/full': {
    label: 'Alerts',
    description: 'View and manage alerts.'
  },
  'api_keys/full': {
    label: 'API Keys',
    description: 'Manage API keys.'
  },
  'ab_testing/full': {
    label: 'A/B Testing',
    description: 'View and manage A/B tests of email templates.'
  },
  'domains/full': {
    label: 'Domains',
    description: 'View and manage sending, bounce, and tracking domains.'
  },
  'events/read': {
    label: 'Events',
    description: 'View and search message events.'
  },
  'ip_pools/full': {
    label: 'IP Pools',
    description: 'View and manage IP pools.'
  },
  'recipient_lists/full': {
    label: 'Recipient Lists',
    description: 'View and manage recipient lists.'
  },
  'seeds/full': {
    label: 'Seeds',
    description: 'View seeding activity and manage seed list settings.'
  },
  'signals_analytics/full': {
    label: 'Signals Analytics',
    description:
      'View and manage analytics metrics, dashboards, and reports, including health score, spam traps, engagement recency, and blocklist incidents.'
  },
  'subaccounts/full': {
    label: 'Subaccounts',
    description: 'Manage subaccounts.'
  },
  'suppressions/full': {
    label: 'Suppressions',
    description: 'View and manage suppressions.'
  },
  'templates/full': {
    label: 'Templates',
    description: 'View, manage, and preview email templates and snippets.'
  },
  'webhooks/full': {
    label: 'Webhooks',
    description: 'View, manage, and test webhooks.'
  }
};

export const SUBACCOUNT_ROLES = [ROLES.SUBACCOUNT_REPORTING];
