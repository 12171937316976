import { formatDate } from '@sparkpost/report-builder/helpers/date';
import React from 'react';
import { Heading } from 'src/components/text';
import { formatCurrency } from 'src/helpers/units';
import styles from './PlanSummary.module.scss';

const PlanSummary = ({
  plan: {
    period,
    plan_volume: planVolume,
    plan_volume_per_period: planVolumePerPeriod,
    overage,
    recurring_charge: recurringCharge
  },
  pendingCancellation = {}
}) => {
  const cost =
    recurringCharge === 0
      ? 'free'
      : `${formatCurrency(recurringCharge)} per ${period || 'month'}`;
  const volume = (planVolumePerPeriod || planVolume).toLocaleString();
  const { effective_date } = pendingCancellation;
  const formattedEffectiveDate = formatDate.useMomentInput(effective_date);

  return (
    <React.Fragment>
      <Heading as="h6" className={styles.Headline} looksLike="h5">
        {volume} emails for {cost}
        {effective_date && (
          <small> to end {formattedEffectiveDate} when your account will be cancelled</small>
        )}
      </Heading>
      {overage && <p>{formatCurrency(overage)} per thousand extra emails</p>}
    </React.Fragment>
  );
};

export default PlanSummary;
